<template>
  <div>
    <v-tabs>
      <v-tab>Operaciones con folios</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-title>Folios de los ultimos 60 días</v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col">
                <v-select
                  v-model="wellboat"
                  :items="wellboats"
                  item-text="nombre"
                  item-value="id"
                  label="Seleccione Wellboat"
                  placeholder="Seleccione Wellboat"
                  return-object
                ></v-select>
              </div>
              <div class="col">
                <v-text-field
                  placeholder="dias"
                  label="Ver Hasta (Cantidad dias)"
                  v-model="dias"
                  dense
                >
                </v-text-field>
              </div>
              <div class="col">
                <v-text-field
                  placeholder="Folio"
                  label="N° Folio"
                  v-model="folio"
                  dense
                >
                </v-text-field>
              </div>
              <div class="col">
                <v-btn @click="loadPartesFiltro">Filtrar</v-btn>
              </div>
              <div class="col">
                <v-btn @click="limpiarFiltros">Limpiar</v-btn>
              </div>
            </div>

            <v-data-table
              :headers="partesHeaders"
              :items="partes"
              :search="search"
              :header-props="{ sortIcon: 'arrow_upward' }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'navigate_befores',
                nextIcon: 'navigate_next',
                //'items-per-page-options':[5,10,20],
              }"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left v-if="item.sincronizado == 0">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      medium
                      class="mr-2"
                      @click="sincronizarParte(item)"
                      v-on="on"
                    >
                      sync
                    </v-icon>
                  </template>
                  <span>Sincronizar parte</span>
                </v-tooltip>
                <v-tooltip left v-if="item.sincronizado == 1">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      medium
                      class="mr-2"
                      @click="desincronizarParte(item)"
                      v-on="on"
                    >
                      sync_disabled
                    </v-icon>
                  </template>
                  <span>Desincronizar</span>
                </v-tooltip>
                <v-tooltip left v-if="item.temporal == 0">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      medium
                      class="mr-2"
                      @click="habilitarParte(item)"
                      v-on="on"
                    >
                      check
                    </v-icon>
                  </template>
                  <span>Habilitar para edición</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon medium @click="deleteParte(item)" v-on="on">
                      delete
                    </v-icon>
                  </template>
                  <span>Eliminar parte</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    tab: 0,
    title: "Nueva Cuenta",
    color: "green",
    snackbar: false,
    errors: [],
    validation_message: "",
    wellboats: [],
    wellboat: {},
    partes: [],
    parte: {},
    dias: 60,
    folio: null,
    partesHeaders: [
      {
        text: "Wellboat",
        align: "center",
        sortable: true,
        value: "wellboat.nombre",
        width: "200px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Faena",
        align: "center",
        sortable: true,
        value: "tipoparte.nombre",
        width: "200px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Interno/ A C Cultivo / A C Acopio",
        align: "center",
        sortable: true,
        value: "registro.nombre",
        width: "200px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Empresa",
        align: "center",
        sortable: true,
        value: "cliente.razon_social",
        width: "200px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Punto Origen",
        align: "center",
        sortable: true,
        value: "tipo_origen.nombre",
        width: "200px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Origen",
        align: "center",
        sortable: true,
        value: "origen.nombre",
        width: "200px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Destino",
        align: "center",
        sortable: true,
        value: "destino.nombre",
        width: "200px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Folio",
        align: "center",
        sortable: true,
        value: "folio",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
        class: "blue-grey darken-4 white--text",
      },
    ],
    search: null,
  }),
  mounted() {
    this.loadWellboats();
  },
  methods: {
    async loadWellboats() {
      let url = `${this.base_url}wellboats/lista/select`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.wellboats = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadPartesFiltro() {
      let url = `${this.base_url}partes/utilitarios`;
      let body = {
        wellboats_id: this.wellboat.id,
      };
      if (this.dias) {
        body["dias"] = this.dias;
      }
      if (this.folio) {
        body["folio"] = this.folio;
      }
      await this.axios
        .post(url, body, this.headers_json)
        .then((response) => {
          this.partes = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadPartes() {
      let url = `${this.base_url}partes/por_wellboat/${this.wellboat.id}`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.partes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sincronizarParte(item) {
      this.$confirm("Desea sincronizar este parte?").then((res) => {
        if (res) {
          let url = `${this.base_url}partes/sincronizar/${item.id}`;
          this.axios
            .get(url, this.headers)
            .then((response) => {
              console.log(response);
              this.showSnackBar(false);
            })
            .catch((error) => {
              this.validation_message = error;
              this.showSnackBar(true);
            });
        }
      });
    },
    desincronizarParte(item) {
      this.$confirm("Desea marcar como no sincronizado este parte?").then(
        (res) => {
          if (res) {
            let url = `${this.base_url}partes/desincronizar/${item.id}`;
            this.axios
              .get(url, this.headers)
              .then((response) => {
                console.log(response);
                this.showSnackBar(false);
              })
              .catch((error) => {
                this.validation_message = error;
                this.showSnackBar(true);
              });
          }
        }
      );
    },
    async deleteParte(item) {
      this.$confirm("Desea borrar este parte?").then((res) => {
        if (res) {
          let url = `${this.base_url}partes/${item.id}`;
          this.axios
            .delete(url, this.headers)
            .then((response) => {
              console.log(response);
              this.loadPartes();
              this.showSnackBar(false);
            })
            .catch((error) => {
              this.validation_message = error;
              this.showSnackBar(true);
            });
        }
      });
    },
    async habilitarParte(item) {
      this.$confirm("Desea habilitar este parte para edición?").then((res) => {
        if (res) {
          let url = `${this.base_url}partes/habilitar/${item.id}`;
          this.axios
            .get(url, this.headers)
            .then((response) => {
              console.log(response);
              this.loadPartes();
              this.showSnackBar(false);
            })
            .catch((error) => {
              this.validation_message = error;
              this.showSnackBar(true);
            });
        }
      });
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },

    limpiarFiltros() {
      this.wellboat = {};
      this.dias = 60;
      this.folio = null;
    },
    ...mapMutations(["actualizarBaseUrl", "mostrarLoading", "ocultarLoading"]),
  },
  computed: {
    ...mapState(["loading", "base_url", "headers", "headers_json"]),
  },
  components: {},
};
</script>
